import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const version = "20240627.1"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const version = \"[^\"]*\"/const version = \"$new_version\"/" "$file_path"
  do not remove it */

const applicationId = "4ff856b4-58de-43a9-a475-097cca93cf13"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const version = \"[^\"]*\"/const version = \"$new_version\"/" "$file_path"
  do not remove it */

const clientToken = "pubbf5de825c7e1ed68fd29a4a64112878a"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const version = \"[^\"]*\"/const version = \"$new_version\"/" "$file_path"
  do not remove it */

const aplicationEnvironment = "prod"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const aplicationEnvironment = \"[^\"]*\"/const aplicationEnvironment = \"$(APPLICATION_ENVIRONMENT)\"/" "$file_path"
  do not remove it */

const service = "abi-supply-global-digitalworkstation-mfe-auth";
const site = "datadoghq.com";

datadogRum.init({
  applicationId,
  clientToken,
  site,
  service,
  env: aplicationEnvironment,
  version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  enableExperimentalFeatures: ["clickmap"]
});

datadogRum.startSessionReplayRecording();
datadogLogs.init({
  clientToken,
  site,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service,
  env: aplicationEnvironment,
  version,
});